import { graphql, useStaticQuery, navigate } from 'gatsby'
import React, { useRef, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Button, Layout } from '../components'
import { Clock, Check } from '../components/icons'
import { When } from '../styling/when'
import { useMediaMatch, useFormSubmit } from '../util'

const Carreer_title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  padding-bottom: 100px;
  flex-grow: 3;
`

const H = styled.h1`
  font-size: 28px;
  font-style: normal;
  font-family: Poppins;
  line-height: 72px;
  font-weight: 700;
  color: #212121;

  ${When.Large} {
    font-size: 48px;
  }
`
const P = styled.p`
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 24px;
  line-height: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  color: #212121;

  ${When.Large} {
    font-size: 14px;
    line-height: 21px;
  }
`
const Cv = styled.p`
  display: none;

  ${When.Large} {
    display: block;
    font-size: 14px;
    margin-top: 0;
    line-height: 21px;
    font-family: Poppins;
    font-weight: 600;
    font-style: normal;
  }
`

const BottleSection = styled.div`
  position: relative;
  bottom: 0px;
`

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
`

const LeftBox = styled.div`
  display: none;

  ${When.Medium} {
    display: block;
    max-width: 276px;
    width: 90%;
    /* margin-right: 150px; */
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
  }
`

const Img = styled.img`
  width: 100%;
  display: block;
  /* height: 100%; */
`
const RightBox = styled.div`
  max-width: 448px;
  width: 90%;
  box-sizing: border-box;
  margin-bottom: 70px;

  ${When.Medium} {
    margin-left: 150px;
  }
`

const Job = styled.div``

const TextH = styled.h3`
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 4px;
  line-height: 27px;
  font-weight: 600;
  color: #212121;
  font-style: normal;
  font-family: Poppins;
`

const DueDate = styled.div`
  display: flex;
  align-items: center;
`

const Span = styled.span`
  font-size: 12px;
  color: #9e9e9e;
  line-height: 18px;
  font-weight: 600;
  font-family: Poppins;
  margin-left: 10px;
`

const Lines = styled.div`
  max-width: 448px;
  width: 90%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 32px;
`
const TextP = styled.p`
  max-width: 351px;
  width: 100%;
  font-size: 12px;
  margin-bottom: 32px;
  line-height: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;

  ${When.Large} {
    font-size: 14px;
    line-height: 21px;
  }
`
const View = styled.p`
  display: none;

  ${When.Large} {
    display: block;
    color: #212121;
    font-size: 14px;
    line-height: 21px;
    margin-top: 24px;
    font-family: Poppins;
    font-weight: 600px;
    font-style: normal;
  }
`

// Success Styles

const SuccessOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const PostMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  width: 90%;

  ${When.Large} {
    max-width: 350px;
    width: 100%;
  }
`
const Round = styled.div`
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  margin-bottom: 12px;
  background-color: #4dae50;

  display: flex;
  justify-content: center;
  align-items: center;
`

const YourMessageWasSent = styled.h3`
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  color: #212121;

  ${When.Large} {
    font-size: 24px;
    margin-bottom: 10px;
  }
`
const WeWillGetInTouch = styled.p`
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  color: #212121;
  text-align: center;

  ${When.Large} {
    font-size: 14px;
    max-width: 279px;
    width: 90%;
    margin-bottom: 18px;
  }
`
const MessageSentSendBox = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
`

const BackToHomeButton = styled(Button)`
  width: 100%;
`

const ErrorText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #F44336;
  margin-top: 15px;
`

// we are tight in time, putting this aside for now
const enableRightBox = false

export default function Career({ location }) {
  const { allStrapiJob } = useStaticQuery(query)

  const showSuccess = useMemo(() => (new URLSearchParams(location.search).get('submitted') === 'true'), [location])

  const fileInputRef = useRef<HTMLInputElement>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const { large } = useMediaMatch();

  const { formSubmitEmails: { career: destinationEmail } } = useFormSubmit();
  const [cvError, setCVError] = useState(null);

  const submitForm = useCallback(async () => {
    const ONE_MB_IN_BYTES = 1048576;
    const ALLOWED_MAX_MBs = 5;
    if (fileInputRef.current.files[0].size > ALLOWED_MAX_MBs * ONE_MB_IN_BYTES) {
      fileInputRef.current.value = "";
      setCVError(`CV file size must be less than ${5} Megabytes`)
    } else {
      setCVError(null);
      submitBtnRef.current.click();
    }
  }, [fileInputRef, submitBtnRef])

  if (showSuccess) {
    return <>
      <Layout location={location}>
        <SuccessOverlay>
          <PostMessageWrapper>
            <Round>
              <Check />
            </Round>
            <YourMessageWasSent>Your CV was submitted</YourMessageWasSent>
            {/* <WeWillGetInTouch>
              We will review your submittion get in touch with you
            </WeWillGetInTouch> */}
            <MessageSentSendBox>
              <BackToHomeButton onClick={() => navigate('/')}>
                Back to Home
              </BackToHomeButton>
            </MessageSentSendBox>
          </PostMessageWrapper>
        </SuccessOverlay>
      </Layout>
    </>
  }

  return (
    <Layout location={location}>
      <Carreer_title>
        <H>Career</H>
        <P>Check out all available jobs or just send us the CV</P>
        <form method="POST" action={`https://formsubmit.co/${destinationEmail}`} encType="multipart/form-data" >
          <input type="hidden" name="_next" value={`${location.href}?submitted=true`} />
          <input type="hidden" name="_subject" value="New CV Submittion" />
          <input type="hidden" name="_captcha" value="false" />
          <input ref={fileInputRef} onChange={submitForm} type="file" name="attachment" accept=".pdf,.docx" style={{ display: 'none' }} />
          <button ref={submitBtnRef} type="submit" style={{ display: 'none' }} />
        </form>
        <Button onClick={() => fileInputRef.current.click()}>Upload CV</Button>

        {cvError ? <ErrorText> {cvError} </ErrorText> : null}
      </Carreer_title>
      <BottleSection>
        <Wrapper>
          <LeftBox>
            <Img src="/images/bottle.png"></Img>
          </LeftBox>

          {enableRightBox && (
            <RightBox>
              {(large
                ? allStrapiJob.edges.slice(0, 2)
                : allStrapiJob.edges
              ).map(({ node }) => (
                <Job>
                  <TextH>{node.title}</TextH>
                  <DueDate>
                    <Clock />
                    <Span>Expires on {node.expiresOn}</Span>
                  </DueDate>
                  <TextP>{node.description}</TextP>
                  <Lines></Lines>
                </Job>
              ))}
              <View>View all</View>
            </RightBox>
          )}
        </Wrapper>
      </BottleSection>
    </Layout>
  )
}

const query = graphql`
  query {
    allStrapiJob {
      edges {
        node {
          title
          description
          expiresOn
        }
      }
    }
  }
`
